import './css/app.scss';
import './elements/index.js';
import './pages/index.js';

import Turbolinks from 'turbolinks';

import './modules/scrollreveal.js';
import { $$, $ } from './functions/dom.js';
import { registerWindowHeightCSS } from './modules/window.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { slideDown } from './functions/animation.js';
import { forceInputUppercase } from './functions/string.js';
import TomSelect from 'tom-select'

registerWindowHeightCSS();

document.addEventListener('turbolinks:load', () => {
  clearAllBodyScrollLocks()
  const darkToggle = document.querySelector('#dark-toggle')
  if (darkToggle) {
    darkToggle.addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()
      document.body.classList.toggle('dark')
    })
  }

    // ScrollIntoView elements
    const scrollIntoViewElement = document.querySelector('.js-scrollIntoView')
    if (scrollIntoViewElement) {
        const parent = scrollIntoViewElement.offsetParent
        parent.scrollTop = scrollIntoViewElement.offsetTop - scrollIntoViewElement.offsetHeight
    }

    // Disable browser autocomplete suggests
    document.querySelectorAll('form, input, select, textarea').forEach(i => i.setAttribute('autocomplete', "nope"))

    // document.querySelectorAll('select[multiple]:not([is])')
    document.querySelectorAll('select[multiple]').forEach(s => {
        if (s.classList.contains('select2') || s.classList.contains('select2-withsort')) {
            return;
        }
        new TomSelect(s, {
            plugins: {
                remove_button: { title: 'Supprimer cet élément' }
            },
            maxItems: s.dataset.limit || null,
            // sortField: s.dataset.sortField || {}
            maxOptions: Infinity
        })
    })
    document.querySelectorAll('select.tomselect').forEach(s =>
        new TomSelect(s, {
            plugins: {
                remove_button: { title: 'Supprimer cet élément' }
            },
            maxItems: 1
        })
    )

    const planningSidebarDisplayBar = document.querySelector('.planning-sidebar-displayBar');
    if (planningSidebarDisplayBar)
    {
        planningSidebarDisplayBar.onclick = e => {
            e.preventDefault();
            let planningSidebar = e.target.closest('.planning-sidebar');
            if (planningSidebar)
            {
                planningSidebar.classList.toggle('open');
            }
        }
    }

    const calendar = document.querySelector('#planningCalendar #calendar')
    if (calendar) {
        const items = calendar.querySelectorAll('.fc-event')
        items.forEach(item => {
            item.addEventListener('click', (e) => {
                items.forEach(item => {
                    item.style.zIndex = null
                });
                item.style.zIndex = 999
            });
        })
    }

  const chatBoxContainerElement = document.querySelector('#chat-box-container')
  if (chatBoxContainerElement) {
    chatBoxContainerElement.querySelector('.toggle').addEventListener('click', () => chatBoxContainerElement.classList.toggle('open'))
  }

    // Choices
    //window.current_lang
    jQuery(() => {
        // jQuery('select.select2').select2({
        //     language: { maximumSelected: function (args) {
        //             return 'Vous pouvez seulement sélectionner ' + args.maximum +
        //                 ' ' + ((args.maximum > 1) ? 's' : '');
        //         } }
        // });
        // jQuery('.select2-withsort').select2({
        //     language: { maximumSelected: function (args) {
        //             return 'Vous pouvez sélectionner ' + args.maximum + ((args.maximum > 1) ? ' items' : ' item') +
        //                 ' au maximum';
        //         } },
        //     sorter: function(data) {
        //         return data.sort(function(a, b) {
        //           return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
        //         });
        //     }
        // });

        // document.querySelectorAll('.field-counter-limit').forEach(f => {
        //     let value = f.getAttribute('maxlength') - f.value.length
        //     f.parentElement.innerHTML += `<div class="field-lenght-info" id="${f.id}_field-lenght-info"><span>${value}</span> caractères restants</div>`
        //     f.addEventListener('click', () => console.log('fieldLenghtInfo'))
        // })

        if (jQuery('.field-counter-limit').length > 0) {
            jQuery('.field-counter-limit').each(function(index){
                let $this = jQuery(this);
                let value = $this.attr('maxlength') - $this.val().length;
                $this.parent().append('<div class="field-lenght-info" id="'+ $this.attr('id') +'_field-lenght-info"><span>' + value + '</span> caractères restants</div><div class="clearfix"></div>');
            });
            jQuery(document).on('keyup', '.field-counter-limit', fieldLenghtInfo);
        }

        if (jQuery('.set-cb-help-text').length > 0) {
            jQuery('.set-cb-help-text').each(function(index){
                let $this = jQuery(this),
                    data = $this.data();
                jQuery('input', $this).each(function(){
                    let $input = jQuery(this);
                    $input.parent('.form-check').append('<div class="text-decription text-muted">' + data.helpText[$input.val()] + '</div>')
                });
            });
        }
        // Champs invité
        if (document.getElementById("invited_registration_form_managerLastname")) document.getElementById("invited_registration_form_managerLastname").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("invited_registration_form_tradeName")) document.getElementById("invited_registration_form_tradeName").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("invited_registration_form_corporateName")) document.getElementById("invited_registration_form_corporateName").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("invited_registration_form_city")) document.getElementById("invited_registration_form_city").addEventListener("keyup", forceInputUppercase);
        // Champs étape 1
        if (document.getElementById("identity_registration_form_managerFirstname")) document.getElementById("identity_registration_form_managerFirstname").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("identity_registration_form_managerLastname")) document.getElementById("identity_registration_form_managerLastname").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("identity_registration_form_tradeName")) document.getElementById("identity_registration_form_tradeName").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("identity_registration_form_corporateName")) document.getElementById("identity_registration_form_corporateName").addEventListener("keyup", forceInputUppercase);
        if (document.getElementById("identity_registration_form_city")) document.getElementById("identity_registration_form_city").addEventListener("keyup", forceInputUppercase);
        // Champs étape 2
        if (document.getElementById("activity_registration_form_brandName")) document.getElementById("activity_registration_form_brandName").addEventListener("keyup", forceInputUppercase);
        // Champs étape 4
        
    });


    document.querySelectorAll('form').forEach(form => {
        if (form.getAttribute('disabled')) {
            form.querySelectorAll('input, select, textarea').forEach(element => {
                element.setAttribute('disabled', 'disabled')
            });
        }
    })

  $$('form.js-preventMultiSubmit').forEach(form => {
    form.addEventListener('submit', () => {
      const button = form.querySelector('button[type="submit"]')
      if (button) {
        button.disabled = true
        button.innerText = 'Chargement...'
      }
    })
  })


    const notifier = document.querySelectorAll('.user-notications-action')
    notifier.forEach(item => {
        item.addEventListener('click', (e) => {
            e.preventDefault();

            let $this = jQuery(item),
                data = $this.data();

            if (jQuery('.user-notications-list', $this.parent()).hasClass('show')) {
                jQuery('.user-notications-list', $this.parent()).removeClass('show');
            } else {
                jQuery('.user-notications-list', $this.parent()).addClass('show');
                if (parseInt(jQuery('.badge', $this).html()) > 0) {
                    jQuery.ajax({
                        url: data.route,
                        type: "POST",
                        dataType: "json",
                        data: data.data,
                        async: false,
                        success : function (response) {
                            if (response.status) {
                                jQuery('.badge', $this).html(0).fadeOut(200);
                            }
                        },
                        error   : function (XMLHttpRequest, textStatus, errorThrown) {

                        }
                    });
                }
            }

        });
    });
    // document.querySelectorAll("small[id$=_help]").forEach(element => {
    //     console.log(element);
    // })
})

/**
 * Evite le chargement ajax lors de l'utilisation d'une ancre
 *
 * cf : https://github.com/turbolinks/turbolinks/issues/75
 */
document.addEventListener('turbolinks:click', e => {
  const anchorElement = e.target
  const isSamePageAnchor =
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname

  if (isSamePageAnchor) {
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(e.data.url, Turbolinks.uuid())
    e.preventDefault()
    window.dispatchEvent(new Event('hashchange'))
  }
})

Turbolinks.start()

function fieldLenghtInfo(e) {
    let $this = jQuery(this);
    let limit = $this.attr('maxlength');
    let value = limit - $this.val().length;
    
    if($this.val().length > limit - 5) {
        jQuery('#' + $this.attr('id') + '_field-lenght-info').addClass('text-danger');
    } else {
        jQuery('#' + $this.attr('id') + '_field-lenght-info').removeClass('text-danger');
    }

    jQuery('#' + $this.attr('id') + '_field-lenght-info span').html(value);
    return true;
}

document.addEventListener('turbolinks:load', () => {
    setTimeout(() => {
        setRequiredCheckboxes();
        getEmptyRequiredFields();
    }, 1000);
})

document.addEventListener('TheraUpdateCheckboxDisplay', () => {
     setRequiredCheckboxes();
});

document.addEventListener('DOMContentLoaded', () => {

    // setRequiredCheckboxes();
    getEmptyRequiredFields();

    /* MANAGE activity_registration_form_tourismAndHandicapLabel */
    var tourismAndHandicapLabelSelectField = document.getElementById('activity_registration_form_tourismAndHandicapLabel');
    if (tourismAndHandicapLabelSelectField !== null && typeof tourismAndHandicapLabelSelectField != 'undefined') {

        handleChangeLabeliseTourismeAndHandicapSelectField(tourismAndHandicapLabelSelectField.value);
        tourismAndHandicapLabelSelectField.addEventListener( 'change', function (e) {
            handleChangeLabeliseTourismeAndHandicapSelectField(e.target.value);
        });
    }

    /* MANAGE activity_registration_form_durableApproach */
    var durableApproachSelectField = document.getElementById('activity_registration_form_durableApproach');
    if (durableApproachSelectField != null && typeof durableApproachSelectField != 'undefined') {
        handleChangeDurableApproachSelectField(durableApproachSelectField.value);
        durableApproachSelectField.addEventListener( 'change', function (e) {
            handleChangeDurableApproachSelectField(e.target.value);
        });
    }


    var _targettedModal,
        _triggers = document.querySelectorAll('[data-modal-trigger]'),
        _dismiss = document.querySelectorAll('[data-modal-dismiss]'),
        modalActiveClass = "is-modal-active";

    function showModal(el){
        _targettedModal = document.querySelector('[data-modal-name="'+ el + '"]');
        _targettedModal.classList.add(modalActiveClass);
    }

    function hideModal(event){
        if(event === undefined || event.target.hasAttribute('data-modal-dismiss')) {
            _targettedModal.classList.remove(modalActiveClass);
        }
    }

    function bindEvents(el, callback){
        for (let i = 0; i < el.length; i++) {
            (function(i) {
                el[i].addEventListener('click', function(event) {
                    callback(this, event);
                });
            })(i);
        }
    }

    function triggerModal(){
        bindEvents(_triggers, function(that){
            showModal(that.dataset.modalTrigger);
        });
    }

    function dismissModal(){
        bindEvents(_dismiss, function(that){
            hideModal(event);
        });
    }

    function initModal(){
        if( jQuery('#favorite-searches').length ){
            jQuery('#favorite-searches').appendTo('body');
        }
        if( jQuery('#advanced-form-search').length ){
            jQuery('#advanced-form-search').appendTo('body');
        }


        triggerModal();
        dismissModal();
    }

    initModal();

});

function handleChangeLabeliseTourismeAndHandicapSelectField(selectFieldValue) {

    let tmp = parseInt(selectFieldValue);
    console.log(tmp);
        let conditionalSelectFieldContainer = jQuery('#activity_registration_form_handicapTypes').closest('.ag-conditional-logic');
        if (tmp === 1) {
            conditionalSelectFieldContainer.prop('hidden', false);
            conditionalSelectFieldContainer.attr('hidden', false);
            conditionalSelectFieldContainer.find('label, legend').each(function() {jQuery(this).addClass('required')})
            conditionalSelectFieldContainer.find('input').each(function() {jQuery(this).prop('required', 'required')})
        } else {
            conditionalSelectFieldContainer.find('label, legend').each(function() {jQuery(this).removeClass('required')})
            conditionalSelectFieldContainer.find('input').each(function() {jQuery(this).prop('required', false)})
            conditionalSelectFieldContainer.prop('hidden', true);
            conditionalSelectFieldContainer.attr('hidden', true);
        }
}

function handleChangeDurableApproachSelectField(selectFieldValue) {

        var conditionalSelectField = document.getElementById('activity_registration_form_durableApproachLabelType');

        var conditionalSelectFieldContainer = conditionalSelectField.closest('.ag-conditional-logic');

        if (selectFieldValue == 1) {
            conditionalSelectFieldContainer.removeAttribute('hidden');
        } else {
            conditionalSelectFieldContainer.setAttribute('hidden', 'hidden');
        }
}

function getEmptyRequiredFields() {
    let submit_buttons = document.querySelectorAll("form button[type=submit]");
    
    if (! submit_buttons) return;
    submit_buttons.forEach(button => {
        button.addEventListener("click", () => {
            if (document.querySelector(".modal-th_error-required")) document.querySelector(".modal-th_error-required").remove();
            let fields = document.querySelectorAll("form [required]");
            let checkboxes = document.querySelectorAll("form .form-group.checkbox-th_error-required")

            let checker = false;
            let modal_text = "<span class='close_btn'>&#x2716;</span><p>Le(s) champs suivant(s) doit/doivent être renseignés : </p><ul>";
            fields.forEach(field => {
                field.classList.remove("th_error-required");
                if (field.value.length == 0) {
                    if (! field.closest(".conditional-logic-container[hidden = hidden]") && ! field.closest(".edit-collaborators")  ) {
                        checker = true;

                        let label = field.parentElement.querySelector("legend");
                        if (!label) {
                            label = field.parentElement.querySelector("label");
                        }
                        if (field.type === "checkbox") {
                           // label.classList.add("checkbox-th_error-required");
                        }else if( field.type){
                            field.classList.add("th_error-required");
                            modal_text += "<li>- <label for='"+ label.getAttribute("for") +"'>" + label.innerHTML + "</label></li>";
                        }

                    }
                }
            });

            checkboxes.forEach(checkbox => {
                checker = true;
                let legend =  checkbox.querySelector("legend");
                if (!legend) {
                    legend = checkbox.querySelector("label");
                }
                if (!legend) return;
                
                let first_input = checkbox.querySelector("label").getAttribute("for");
                modal_text += "<li>- <label for='"+ first_input +"'>" + legend.innerHTML + "</label></li>";
            })
            modal_text += "</ul>";

            if (checker == true) {
                let form = button.closest("form");
                if (document.querySelector(".modal-th_error-required")) document.querySelector(".modal-th_error-required").remove();
                let modal_div = document.createElement("div");
                form.parentNode.appendChild(modal_div);
                modal_div.classList.add("modal-th_error-required");
                modal_div.innerHTML = modal_text;

                let close_btn = document.querySelector(".modal-th_error-required .close_btn");

                close_btn.addEventListener("click", () => {
                    document.querySelector(".modal-th_error-required").remove()
                })
            }
        })
    });
}

function setRequiredCheckboxes() {

    let submit_buttons = document.querySelectorAll("form button[type=submit]");
    let checkboxes = document.querySelectorAll("form input[type=checkbox][required]");

    checkboxes.forEach(checkbox => {
        let parent = checkbox.closest(".form-group");
        if (! parent.classList.contains("checkbox_required")) {
            parent.classList.add("checkbox_required");
        }
        checkbox.removeAttribute("required");
    });
    
    submit_buttons.forEach(submit_btn => {
        submit_btn.addEventListener("click", function(evt) {
            checkboxes = document.querySelectorAll("form input[type=checkbox][required]");
            checkboxes.forEach(checkbox => {
                let parent = checkbox.closest(".form-group");
                
                if (! parent.classList.contains("checkbox_required")) {
                    parent.classList.add("checkbox_required");
                }
                checkbox.removeAttribute("required");
            });
            let parents = document.querySelectorAll("form .checkbox_required");
            parents.forEach(parent => {
                parent.classList.remove("checkbox-th_error-required");
                if (!parent.closest(".conditional-logic-container[hidden = hidden]")) {
                    let childrens = parent.querySelectorAll("input[type=checkbox]");
                    let checker = 0;
                    childrens.forEach(child => {
                        if (child.checked) {
                            parent.classList.remove("checkbox-th_error-required");
                            checker = 1;
                        }
                    });
    
                    if (checker === 0 ) {
                        parent.classList.add("checkbox-th_error-required");
                        evt.preventDefault();
                    }
                }
                else {

                    parent.classList.remove("checkbox_required");
                }
            });
        });
    });
}

const mobileMenu = document.getElementById('js-burger');
if( mobileMenu ) {
    mobileMenu.addEventListener('click', function() {
        document.getElementById('header').classList.toggle("is-open");
    });
}

document.querySelectorAll('.header-nav .nav-dropdown span').forEach(function(span) {
    span.addEventListener('click', function() {
        var nextUl = this.nextElementSibling;

        if (getComputedStyle(nextUl).display === "none") {
            nextUl.style.display = "block";
        } else {
            nextUl.style.display = "none";
        }

        var lis = nextUl.querySelectorAll('li');
        lis.forEach(function(li) {
            if (getComputedStyle(li).display === "none") {
                li.style.display = "block";
            } else {
                li.style.display = "none";
            }
        });
    });
});